import * as React from 'react';
import clsx from 'clsx';
import { graphql, useStaticQuery, Link } from 'gatsby';
import loadable from '@loadable/component';
import Img from 'gatsby-image';
//styles
import styles from '../technologies.module.css';
//components
import Achievements from 'components/recognition/achievements';
import Awards from 'components/about-us/awards';
import WorkTypes from '../work-types';
import { UnderlineBullets } from 'components/bullets/titled-bullets';
import ReviewSlider1 from 'components/services/team-augmentation/review-slider1';
import ReviewSlider2 from 'components/services/team-augmentation/review-slider2';
import OfferWidget from 'components/services/team-augmentation/offer-wiget';
import Booking from 'components/feedback';
import Clutch from '../clutch';
import Layout from '../components/layout';
import {
  LadderOut,
  Backbone,
  Gokada,
  Appetini,
  Condogenie,
  BeyondGreen,
  Anuel,
  EveryPig,
  KoroBox,
  Skilent,
} from 'components/carousel';
//meta
import identificators from 'components/googleAnalyticsIdentificators';

const Routes = require('../../routes').Routes;

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const benefitsList = [
  {
    title: 'Save time',
    text: 'Save time by leveraging our React.js developers. In the USA, hiring local engineers takes 60 days on average. Two months is a long time for projects with tight deadlines. Brocoders can get the right frontend talent for you within seven days.',
  },
  {
    title: 'Pick out the best',
    text: 'We will schedule a meeting with you to clarify project details and understand unique requirements. Our dedicated team will present you with qualified candidates, allowing you to conduct your vetting process and interview each candidate personally. Choose the perfect fit for your project, and we will quickly assemble your efficient team.',
  },
  {
    title: 'Reduce costs',
    text: 'By hiring us as an outsourced React.js development company, you eliminate the costs of recruitment, training, office space, energy bills, software licenses, employee benefits, payroll increments, and logistical expenses. You only pay for actual work on a project basis. Moreover, outsourcing of services abroad is an effective way to reduce development costs.',
  },
];

interface Props {
  location: Location;
}

const SwiperSlider = loadable(() => import('components/swiper-slider'));

function ReactJS({ location }: Props) {
  const faqData = useFaqData('allStrapiTechnology', 'React.js');
  const data = useStaticQuery(graphql`
    query {
      qualityDeveloper: file(relativePath: { eq: "react-developer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      title="Hire React.js Developers"
      subtitle="Build your web and mobile solutions with our top-notch React.js developers, delivering high-performance and scalable digital products with the best user experience to meet your business needs."
      metaData={{ main: meta.reactjs, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
      image={<Clutch />}
    >
      <section className="section">
        <Achievements />
      </section>
      <ReviewSlider1 />
      <section className="section">
        <div className="inner">
          <h2 className={styles.title}>Our React.js Expertise</h2>
          <p className={styles.lightPraragraph}>
            Expand your in-house team with our software engineers or let us build a cross-functional
            dedicated team for you.
          </p>
          <ul className={styles.expertiseList}>
            <li className={styles.expertiseItem} key="Progressive Web Apps (PWAs)">
              <p className={styles.expertiseTitle}>Progressive Web Apps (PWAs)</p>
              <p className={styles.expertiseText}>
                Our React.js experts can efficiently help to develop PWAs, providing app-like
                experiences accessible directly from web browsers. We understand the principles of
                PWAs and the specific capabilities offered by React.js to build a robust PWA
                experience. We have deep knowledge of key PWA features, like Service Workers,
                Responsive Design, Offline Support, App Shell, and Web Manifests. Hire our React.js
                developers, and they build fast and reliable PWAs that work seamlessly across
                devices and offer features like push notifications and offline caching.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Data Visualization tools">
              <p className={styles.expertiseTitle}>Data Visualization tools</p>
              <p className={styles.expertiseText}>
                As for data visualization tools, React.js offers a wide range of options and
                libraries that allow developers to create charts, graphs, and dashboards. Through
                our experience with React.js, we can use these tools to transform your data into
                stunning visual representations. Our development team has experience using popular
                React.js data visualization libraries such as D3.js, Victory, Chart.js, React Vis,
                Recharts, Visx, BizCharts, Ant Design Charts, eCharts for React, React-stockcharts
                and React time series charts. We will create intuitive and customizable data-driven
                visualizations for your web applications that best fit your business needs.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Marketplaces">
              <p className={styles.expertiseTitle}>Marketplaces</p>
              <p className={styles.expertiseText}>
                Regarding building marketplaces using React.js, our team possesses extensive
                expertise in developing scalable solutions. We have significant experience in
                various industries, such as{' '}
                <Link className="link" to={Routes.INDUSTRIES_AGRITECH}>
                  Agritech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_MARTECH}>
                  Martech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_PROPTECH}>
                  PropTech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_EDTECH}>
                  EdTech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_FINTECH}>
                  Fintech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.FITNESS_MOBILE_SOLUTIONS_B}>
                  Fitness & Healthcare
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_RIDESHARING}>
                  Ride-sharing
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_FOODTECH}>
                  Foodtech
                </Link>
                ,{' '}
                <Link className="link" to={Routes.INDUSTRIES_EVENTS_MANAGMENT}>
                  Events management
                </Link>
                , Recruting&HR, and E-commerce. With React.js as the frontend framework, we can
                create dynamic user interfaces that facilitate seamless interactions between buyers
                and sellers. By combining React.js with backend technologies, we build robust and
                secure marketplaces with features like user authentication, product listing, search
                functionality, transaction management, and rating systems. This way, we provide
                companies with complex software solutions.
              </p>
            </li>
            <li className={styles.expertiseItem} key="e-Commerce Development">
              <p className={styles.expertiseTitle}>e-Commerce Development</p>
              <p className={styles.expertiseText}>
                Our team of React.js developers has a wealth of background in e-Commerce development
                with the power of the React.js stack. Utilizing React.js frameworks and its
                component reusability feature, we create fast, scalable and SEO-friendly e-Commerce
                solutions that provide seamless user experiences for your customers. We integrate
                all the necessary functionalities, such as secure payment gateways, shopping carts,
                order management, product catalogs, user authentication and authorization, to ensure
                your eCommerce platform stays competitive online marketplace.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Cross-platform app development">
              <p className={styles.expertiseTitle}>Cross-platform app development</p>
              <p className={styles.expertiseText}>
                React Native inherits the functionalities of React.js and shares some similarities.
                React can be used to build web, mobile, and desktop applications, making it a
                versatile cross-platform development framework. Our frontend developers help
                businesses build native-like cross-platform mobile apps and expand their digital
                presence.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.carousel}>
        <SwiperSlider>
          <Anuel />
          <EveryPig />
          <KoroBox />
          <LadderOut />
          <Backbone />
          <Gokada />
          <Appetini />
          <Condogenie />
          <BeyondGreen />
          <Skilent />
        </SwiperSlider>
      </section>

      <WorkTypes />
      <section className="section">
        <div className={styles.technicalAuditContainer}>
          <div className={styles.technicalAuditBox}>
            <h3 className={styles.technicalAuditTitle}>
              Hire Our React.js Developers for Your Next Project
            </h3>
            <p className={styles.technicalAuditText}>
              Get access to our skilled team of React.js developers. Assess their experience with
              detailed CVs, allowing your HR or technical team to identify the most suitable
              candidates with the right mix of hard and soft skills. Embrace the benefits of
              outsourcing as you expand your team with remote specialists and enhance your
              development capabilities. Get cost-effectiveness, quality assurance, and the ability
              to focus on your core business, knowing your development needs are in good hands.
            </p>
            <a
              href="https://share.hsforms.com/1j39L9zW_QBiypvhPObxZ3Q4awz5"
              target="_blank"
              className={styles.technicalAuditLink}
            >
              get top react.js developers cv
            </a>
            <a
              id={identificators.GET_TECH_AUDIT}
              className="btn btn_60 btn__white wideBtn"
              href="https://share.hsforms.com/1j39L9zW_QBiypvhPObxZ3Q4awz5"
            >
              Request engineers you need
            </a>
          </div>
          <Img
            fluid={data.qualityDeveloper.childImageSharp.fluid}
            className={styles.technicalAuditImg}
            alt=""
          />
        </div>
      </section>
      <section className="section gray">
        <div className={clsx('inner-container', styles.marginBotom)}>
          <h2 className={styles.subTitle}>
            Why scale your team with our skilled React.js Developers
          </h2>
          <UnderlineBullets list={benefitsList} />
        </div>
      </section>
      <section className={clsx('section', styles.negativeTop)}>
        <div className="inner">
          <FAQ data={faqData} />
        </div>
      </section>
      <section className="section">
        <ReviewSlider2 />
      </section>

      <section className={clsx('section', styles.awardsContainer)}>
        <Awards
          isWithCaption
          containerStyles="inner"
          captionStyles={clsx(styles.title, styles.marginBottom)}
        />
      </section>

      <OfferWidget />
      <Booking />
    </Layout>
  );
}

export default ReactJS;
