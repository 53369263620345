import React from 'react';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
//styles
import styles from './technologies.module.css';

const Clutch = () => {
  const data = useStaticQuery(graphql`
    query {
      clutch: file(relativePath: { eq: "logo-clutch.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logoClutchMob: file(relativePath: { eq: "logo-clutch.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <picture>
      <source
        srcSet={data.logoClutchMob.publicURL}
        className={clsx(styles.image, styles.nodeLogo)}
        media="(max-width: 768px)"
        type="image/svg+xml"
      />
      <source
        srcSet={data.clutch.childImageSharp.fluid.srcWebp}
        media="(min-width: 769px)"
        type="image/webp"
      />
      <img src={data.clutch.childImageSharp.fluid.src} alt="" className={styles.image} />
    </picture>
  );
};
export default Clutch;
