import * as React from 'react';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
//styles
import styles from './technologies.module.css';
//components
import BulletedList from 'components/bullets/bulleted-list';

const tipesOfWorkList = [
  'Web and mobile application development',
  'User Interface/User Experience (UI/UX) development',
  'Single-Page Applications (SPAs) development',
  'Integration with backend systems and APIs',
  'Component-based development',
];

const WorkTypes = () => {
  const data = useStaticQuery(graphql`
    query {
      cooperationIcon: file(relativePath: { eq: "cooperation.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <section className={clsx('section ', styles.wave)}>
      <div className={clsx('inner-container', styles.typesOfWork)}>
        <h2 className={styles.subTitle}>The type of work we get hired for</h2>
        <div className={styles.typesContainer}>
          <p className={clsx(styles.lightPraragraph, styles.typesText)}>
            It doesn't matter what kind of product you're building. Brocoders can implement a
            project in any industry. Our main strengths lie within the fields of Agile product
            development, software engineering, and cloud architecture.
          </p>
          <BulletedList
            list={tipesOfWorkList}
            listBoxStyle={styles.typesList}
            itemStyle={styles.typesItem}
          />
          <img
            src={data.cooperationIcon.publicURL}
            className={styles.cooperationImg}
            alt="technical Audit Logo"
          />
        </div>
      </div>
    </section>
  );
};

export default WorkTypes;
